import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/tutorials.tsx";
export const pageTitle = "Creating a server";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TutorialSteps = makeShortcode("TutorialSteps");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "creating-a-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#creating-a-server",
        "aria-label": "creating a server permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating a server`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#what-you-need"
        }}>{`What you need`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#1-create-a-server-instance"
        }}>{`1. Create a server instance`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#2-start-the-server"
        }}>{`2. Start the server`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#3-run-the-server-and-service"
        }}>{`3. Run the server and service`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#whats-next"
        }}>{`What's next`}</a></li>
    </ul>
    <p>{`As the first step of the tutorial, we create a server instance and run a dummy service to check that the server and service are launched.
We'll use Armeria's `}<a parentName="p" {...{
        "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
      }}>{`type://ServerBuilder`}</a>{` for this task.`}</p>
    <TutorialSteps current={1} mdxType="TutorialSteps" />
    <h2 {...{
      "id": "what-you-need",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-you-need",
        "aria-label": "what you need permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What you need`}</h2>
    <p>{`No preparation is required for this step. Do check that you've prepared the `}<a parentName="p" {...{
        "href": "/tutorials/rest/blog/#prerequisites"
      }}>{`prerequisites`}</a>{`.`}</p>
    <h2 {...{
      "id": "1-create-a-server-instance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1-create-a-server-instance",
        "aria-label": "1 create a server instance permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Create a server instance`}</h2>
    <p>{`Let's create a server instance using Armeria's `}<a parentName="p" {...{
        "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
      }}>{`type://ServerBuilder`}</a>{`:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create a `}<inlineCode parentName="p">{`Main`}</inlineCode>{` class. You can see the full version of the file `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria-examples/blob/main/tutorials/rest-api-annotated-service/src/main/java/example/armeria/server/blog/Main.java"
          }}>{`here`}</a>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java",
            "filename": "Main.java"
          }}>{`package example.armeria.server.blog;

import org.slf4j.Logger;
import org.slf4j.LoggerFactory;

public final class Main {
  private static final Logger logger = LoggerFactory.getLogger(Main.class);
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`Main`}</inlineCode>{` class, add the method, `}<inlineCode parentName="p">{`newServer()`}</inlineCode>{`. Since a server instance requires at least one service to run with, let's add a dummy service returning `}<inlineCode parentName="p">{`"Hello, Armeria!"`}</inlineCode>{` for now.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java highlight=10",
            "filename": "Main.java",
            "highlight": "10"
          }}>{`import com.linecorp.armeria.common.HttpResponse;
import com.linecorp.armeria.server.Server;
import com.linecorp.armeria.server.ServerBuilder;

public final class Main {
  ...
  static Server newServer(int port) {
    ServerBuilder sb = Server.builder();
    return sb.http(port)
             .service("/", (ctx, req) -> HttpResponse.of("Hello, Armeria!"))
             .build();
  }
  ...
`}</code></pre>
        <p parentName="li">{`To learn how to add the actual blog service, see `}<a parentName="p" {...{
            "href": "/tutorials/rest/blog/add-services-to-server"
          }}>{`Step 3. Add services to a server`}</a>{`.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "2-start-the-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#2-start-the-server",
        "aria-label": "2 start the server permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Start the server`}</h2>
    <p>{`Now that we have a server, have a go at starting the server with the dummy service.`}</p>
    <ol>
      <li parentName="ol">{`In the `}<inlineCode parentName="li">{`Main`}</inlineCode>{` class, add the `}<inlineCode parentName="li">{`main()`}</inlineCode>{` method.`}</li>
      <li parentName="ol">{`Call the `}<inlineCode parentName="li">{`newServer()`}</inlineCode>{` method we implemented in step 2. Let's set the port to `}<inlineCode parentName="li">{`8080`}</inlineCode>{` as in line 2.`}</li>
      <li parentName="ol">{`Start the server as in line 9.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=Main.java highlight=2,9 showlineno=true",
        "filename": "Main.java",
        "highlight": "2,9",
        "showlineno": "true"
      }}>{`public static void main(String[] args) throws Exception {
  Server server = newServer(8080);

  server.closeOnJvmShutdown();

  server.start().join();

  logger.info("Server has been started. Serving dummy service at http://127.0.0.1:{}",
              server.activeLocalPort());
}
`}</code></pre>
    <h2 {...{
      "id": "3-run-the-server-and-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#3-run-the-server-and-service",
        "aria-label": "3 run the server and service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Run the server and service`}</h2>
    <p>{`As the last step, launch the server and run the service to check if you're all set to go. To run the server, run the `}<inlineCode parentName="p">{`main()`}</inlineCode>{` method in your IDE. The server and service are launched successfully if you see the following message.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{` Server has been started. Serving dummy service at http://127.0.0.1:8080
`}</code></pre>
    <p>{`Open the URL `}<inlineCode parentName="p">{`http://127.0.0.1:8080`}</inlineCode>{` on a web browser and check the message `}<inlineCode parentName="p">{`Hello, Armeria!`}</inlineCode>{` is displayed in the page.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "20.245398773006134%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAATklEQVQY052PwQkAQQgD7b9DX2oBNuCqOfwc+1puTxAEkyEhEYGqwt0xNzPDzLDWQnejqj7taCMCNMb9kZmv4HaGRX+MR+Akuql2qjzAB36NO47yT0YXAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "tutorial test run",
          "title": "tutorial test run",
          "src": "/static/6aa849a0f01434e9873c30e3a6023ab2/a6d36/tutorial_test_run.png",
          "srcSet": ["/static/6aa849a0f01434e9873c30e3a6023ab2/222b7/tutorial_test_run.png 163w", "/static/6aa849a0f01434e9873c30e3a6023ab2/ff46a/tutorial_test_run.png 325w", "/static/6aa849a0f01434e9873c30e3a6023ab2/a6d36/tutorial_test_run.png 650w", "/static/6aa849a0f01434e9873c30e3a6023ab2/65c7b/tutorial_test_run.png 795w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "whats-next",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#whats-next",
        "aria-label": "whats next permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What's next`}</h2>
    <p>{`In this step, you've built a server, added a dummy service and launched a server.`}</p>
    <p>{`Next, at `}<a parentName="p" {...{
        "href": "/tutorials/rest/blog/prepare-data-object"
      }}>{`Step 2. Prepare a data object`}</a>{`, you'll write a data object to specify and contain blog post information.`}</p>
    <TutorialSteps current={1} mdxType="TutorialSteps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      